/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

/* Meyers CSS Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:root {
  font-size: 20px;
  font-family: sans-serif;
  color: var(--main_text_clr);
  scroll-behavior: smooth;
  --background_clr: #eee0cb;
  --accent_background_clr: #fcf0e5;
  /* Text */
  --body_font: 1.3em;
  --main_text_clr: #4b5043;
  --accent_line_clr: #707070;
  --title_side: 3.3em;
  --about_text_center_padding: 30px;
  /* Space */
}

.App-parent {
  background-color: var(--background_clr);
}
.content-pane {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  -ms-scroll-snap-points-y: repeat(100vh);
  scroll-snap-points-y: repeat(100vh);
}
.landing_page,
.about_page,
.interest_page,
.contact_page {
  min-height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr 1fr;
  grid-template-columns: 1fr 4fr 1fr;
  scroll-snap-align: center;
}
.landing_page {
  max-height: 100vh;
}
.hidden {
  display: none;
}
/* .about_page {

} */

.left_pane,
.right_pane {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  /* padding-top: 45px; */
  text-align: center;
  /* border: 1px solid black; */
}

.vertical_line {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 10px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.vertical_line hr {
  border-style: solid;
  width: 34vh;
}

/* Start - Left Pane Component */
.left_pane {
  margin-left: 20%;
  display: -ms-grid;
  display: grid;
  height: 100vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
nav {
  list-style-type: none;
}
nav a {
  font-size: var(--body_font);
  color: var(--main_text_clr);
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 10px;
}
nav a:hover {
  text-decoration: underline;
}
.page_title {
  font-size: var(--title_side);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.page_title p {
  width: 75px;
}

@keyframe .forbidden_icon {
  height: 60px;
  position: absolute;
  bottom: 0;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  -webkit-animation-name: rotation;
  animation-name: rotation;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* End - Left Pane Component */

/* Start - Right Pane Component */
.right_pane {
  margin-right: 20%;
}
.right_pane img {
  height: 105px;
  padding-top: 15px;
}
/* End - Right Pane Component */

/* Start - Landing Page */

.landing_main_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.landing_page .left_pane,
.landing_page .right_pane {
  padding-top: 20px;
}
.landing_page .menu-icon {
  display: none;
}
.landing_page .left_pane img {
  height: 50px;
  width: 50px;
}

.main_text_container p {
  font-size: 4em;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.main_text_container p:first-child {
  font-weight: 200;
}
.main_text_container p:last-child {
  font-weight: 600;
  font-style: italic;
  text-shadow: -3px 2px 4px black;
}

/* End - Landing Page */

/* Start - About Page */

.about_page_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: justify;
}
.about_page_content div {
  width: 50%;
  height: 50vh;
}
.about_page_content div:first-child {
  border-right: 2px solid var(--accent_line_clr);
  padding-right: var(--about_text_center_padding);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.about_page_content div:first-child p,
.about_page_content div:last-child p {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.about_page_content div:last-child {
  padding-left: var(--about_text_center_padding);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* End - About Page */

/* Start - Interest Page  */
.interest_page .page_title {
  /* font-size: calc(var(--title_side) - 1.5em); */
}
.interest_page_content {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 7fr 1fr;
  grid-template-rows: 1fr 7fr 1fr;
}

.interest_page_content .outside_row {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.interest_page_content .outside_row span {
  height: 60px;
  border: solid 1px var(--accent_line_clr);
}

.interest_page_content #row2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.interest_page_content #row2 > div:first-child {
  color: white;
  height: 100%;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.interest_page_content #row2 > div:last-child {
  width: 50%;
  padding: 0 var(--about_text_center_padding);
  text-align: justify;
}

/* End - Interest Page */

/* Start - Interest Box */
.interest_box_container > div {
  height: inherit;
}
.interest_box_wrapper {
  max-height: 550px;
  border: 6px solid var(--accent_line_clr);
  height: inherit;
  width: 100%;
  max-width: 425px;
  margin: auto;
  display: -ms-grid;
  display: grid;
  grid-template-areas:
    'img1 img2'
    'img1 img2'
    'img1 img4'
    'img3 img4'
    'img3 img4';
}
.interest_box_wrapper > div {
  border: 1px solid black;
}
.interest_box_wrapper > div:hover {
}
.interest_box_wrapper > div:first-child {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  grid-area: img1;
  background: red;
}

.interest_box_wrapper > div:nth-child(2) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 3;
  -ms-grid-column: 2;
  grid-area: img4;
  background: yellow;
}
.interest_box_wrapper > div:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  grid-area: img2;
  background: grey;
}
.interest_box_wrapper > div:last-child {
  -ms-grid-row: 4;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: img3;
  background: pink;
}

.interest_video {
  height: 100%;
  max-width: 210.3px;
  max-height: 309.5px;
  background-size: contain;
}
.interest_music {
  height: 100%;
  max-height: 208px;
  max-width: 206px;
}

/* End - Interest Box */
/* Start - Contact */

.contact_page_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact_inner_wrapper {
  background: var(--accent_background_clr);
  min-height: 50vh;
  width: 50vw;
  border: 6px solid;
}
.contact_inner_wrapper h2 {
  margin: 10vh 0 8vh 0;
  font-size: 2em;
  text-align: center;
}
.contact_form_wrapper {
  width: 100%;
  margin: auto;
}
.contact_form_wrapper form {
  max-width: 80%;
  margin: 0 auto;
}

.contact_form_wrapper .input_container input,
.contact_form_wrapper .input_container textarea {
  color: var(--main_text_clr);
  font-size: var(--body_font);
  /* background: rgba(255, 255, 255, 0.55); */
  width: 83%;
  margin: 0 10%;
  background: none;
  border-style: none;
  border-bottom: 3px solid var(--accent_line_clr);
  padding-bottom: 10px;
}
.contact_form_wrapper .input_container input:focus,
.contact_form_wrapper .input_container textarea:focus {
  outline: none;
}
@-webkit-keyframes textAreaGrow {
  0% {
    padding-bottom: 0;
    opacity: 0;
    border-bottom: 1px solid var(--accent_line_clr);
  }
  30% {
    opacity: 1;
  }
  50% {
    padding-bottom: 0%;
  }
  100% {
    min-height: 30vh;
    padding-bottom: 10%;
    opacity: 1;
    border-bottom: 3px solid var(--accent_line_clr);
  }
}
@keyframes textAreaGrow {
  0% {
    padding-bottom: 0;
    opacity: 0;
    border-bottom: 1px solid var(--accent_line_clr);
  }
  30% {
    opacity: 1;
  }
  50% {
    padding-bottom: 0%;
  }
  100% {
    min-height: 30vh;
    padding-bottom: 10%;
    opacity: 1;
    border-bottom: 3px solid var(--accent_line_clr);
  }
}
@-webkit-keyframes textAreaShrink {
  0% {
    min-height: 30vh;
    padding-bottom: 10%;
    opacity: 1;
    border-bottom: 3px solid var(--accent_line_clr);
    display: block;
  }
  30% {
  }
  50% {
  }

  100% {
    padding: 0;
    opacity: 0;
    border-bottom: 1px solid var(--accent_line_clr);
    margin-top: 0px;
    display: none;
    height: 0px;
    min-height: 0px;
  }
}
@keyframes textAreaShrink {
  0% {
    min-height: 30vh;
    padding-bottom: 10%;
    opacity: 1;
    border-bottom: 3px solid var(--accent_line_clr);
    display: block;
  }
  30% {
  }
  50% {
  }

  100% {
    padding: 0;
    opacity: 0;
    border-bottom: 1px solid var(--accent_line_clr);
    margin-top: 0px;
    display: none;
    height: 0px;
    min-height: 0px;
  }
}
.contact_form_wrapper .input_container textarea {
  min-height: 7vh;
  opacity: 0;
  padding-bottom: 0px;
  border-bottom: 3px solid var(--accent_line_clr);
  margin-top: 5%;
  -webkit-animation-name: textAreaGrow;
  animation-name: textAreaGrow;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: 0.01s;
  animation-delay: 0.01s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.contact_form_wrapper .input_container textarea.shrink {
  min-height: 30vh;
  padding-bottom: 10%;
  opacity: 1;
  border-bottom: 3px solid var(--accent_line_clr);
  -webkit-animation-name: textAreaShrink;
  animation-name: textAreaShrink;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: 0.01s;
  animation-delay: 0.01s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.contact_form_wrapper .input_container span:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2vh;
  margin-bottom: 10vh;
  padding: 0 2vw;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.contact_form_wrapper .input_container span:last-child.button_first-next {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.contact_form_wrapper .input_container span:last-child button {
  background: none;
  border: none;
  font-size: var(--body_font);
}
.contact_form_wrapper .contactErrorMessage {
  width: 83%;
  margin: 10px 10% 0 10%;
}
.right_pane.invert {
  padding-bottom: 34%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.right_pane.invert img {
  transform: rotate(180deg);
}

/*  */
@media only screen and (max-width: 1145px) {
  .interest_page_content {
    -ms-grid-rows: 1fr 10fr 1fr;
    grid-template-rows: 1fr 10fr 1fr;
  }
  .interest_page_content #row2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .interest_page_content #row2 > div:first-child,
  .interest_page_content #row2 > div:last-child {
    width: 80%;
    padding: 0;
  }
  .interest_page_content #row2 > div:last-child {
    text-align: justify;
  }
  .interest_page_content .outside_row span {
    border: none;
  }
  .interest_box_container > div {
    min-height: 500px;
    margin-bottom: 4vh;
  }
}
@media only screen and (max-width: 1024px) {
  .main_text_container {
    font-size: 0.9rem;
  }

  /* Interest Page */

  /* Contact Page */
  .contact_inner_wrapper h2 {
    font-size: 1.5em;
    margin: 9vh 0 7vh 0;
  }

  .contact_form_wrapper .input_container span {
    margin: 7vh 0 0vh 0;
    font-size: 1em;
  }
  .contact_form_wrapper .input_container input,
  .contact_form_wrapper .input_container span:last-child {
    font-size: 1.2em;
  }
  .contact_form_wrapper .input_container span:last-child {
    font-size: 1em;
    margin-bottom: 5vh;
  }
  .contact_form_wrapper .input_container span:last-child button,
  .contact_form_wrapper .input_container textarea {
    font-size: 1em;
  }
}
@media only screen and (max-width: 900px) {
  .about_page,
  .interest_page,
  .contact_page,
  .interest_page_content {
    display: block;
  }
  .about_page .left_pane,
  .interest_page .left_pane,
  .contact_page .left_pane {
    margin-left: 0;
    height: 20vh;
    position: relative;
    padding-top: 0px;
  }
  .about_page .page_title p,
  .interest_page .page_title p,
  .contact_page .page_title p {
    width: 100%;
  }
  .about_page {
    min-height: 80vh;
  }
  .about_page_content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .about_page_content div {
    min-height: 30vh;
    max-height: 35vh;
    width: 80%;
  }
  .about_page_content > div:first-child,
  .about_page_content > div:last-child {
    border: 0;
    padding: 0;
  }
  .about_page_content > div:first-child {
    border-bottom: 2px solid var(--accent_line_clr);
  }

  .about_page .right_pane,
  .interest_page .interest_page_content .outside_row,
  .contact_page .right_pane,
  .interest_page .right_pane {
    display: none;
  }
  .interest_box_wrapper {
    max-width: 450px;
    min-height: 570px;
  }
  .interest_page_content #row2 > div:last-child {
    width: 80%;
  }
  .contact_inner_wrapper {
    width: 80%;
  }
  .contact_inner_wrapper h2 {
    margin: 10vh 0 8vh 0;
    font-size: 2em;
  }
  .contact_inner_wrapper span:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .contact_inner_wrapper .input_container input {
    margin: 0;
    width: 80%;
  }
}

@media only screen and (max-width: 700px) {
  .main_text_container {
    font-size: 0.7rem;
  }
  .left_pane {
    display: -ms-grid;
    display: grid;
  }
  nav {
    position: absolute;
    margin-top: 20px;
    font-size: 16px;
  }
  .right_pane img {
    height: 75px;
  }

  /* .contact_form_wrapper .input_container input {
    font-size: 1.2em;
  } */
}

@media only screen and (max-width: 650px) {
  .page_title {
    font-size: 2.5em;
  }
  .about_page_content > div:first-child {
    border-bottom: 0px;
    margin-bottom: 20px;
  }
  .about_page_content > div {
    min-height: unset;
    max-height: unset;
    height: auto;
  }
}
@media only screen and (max-width: 600px) {
  .contact_inner_wrapper h2 {
    font-size: 1.6em;
  }
  .landing_page .menu-icon {
    display: block;
  }
  .landing_page .menu-icon.hidden {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .page_title {
    font-size: 2em;
  }
  .contact_inner_wrapper h2 {
    font-size: 1.3em;
  }
  .landing_page .right_pane {
    display: none;
  }
  .main_text_container {
    font-size: 0.6rem;
  }
  .contact_form_wrapper .input_container input,
  .contact_form_wrapper .input_container span:last-child {
    font-size: 1em;
  }
}

/* HEIGHT */

@media only screen and (max-height: 600px) {
  .about_page .left_pane,
  .interest_page .left_pane,
  .contact_page .left_pane {
    font-size: 0.8em;
  }
  .page_title p {
    width: 40px;
  }
  .right_pane img {
    height: 75px;
  }
}

@media only screen and (max-height: 480px) {
  .about_page .left_pane,
  .interest_page .left_pane,
  .contact_page .left_pane {
    font-size: 0.65em;
  }
  .main_text_container p {
    font-size: 3em;
  }

  /* .contact_inner_wrapper h2 {
    font-size: 1.8em;
  } */
  .contact_form_wrapper .input_container textarea,
  .contact_form_wrapper .input_container input,
  .contact_form_wrapper .input_container span:last-child button {
    font-size: calc(var(--body_font) - 4px);
  }
}

@media only screen and (max-height: 480px) and (max-width: 1024px) {
  .contact_inner_wrapper h2 {
    font-size: 1.5em;
  }
}

@media only screen and (max-height: 655px) {
  .about_page_content div {
    height: auto;
  }
}
